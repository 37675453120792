// import React, { useEffect, useState } from "react";
// import styled from "styled-components";
// import { InlineWidget } from "react-calendly";

// const ModalOverlay = styled.div`
//   position: fixed;
//   inset: 0;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   background-color: rgba(0, 0, 0, 0.5);
//   z-index: 1000;
// `;

// const ModalContainer = styled.div`
//   background-color: white;
//   border-radius: 8px;
//   overflow: hidden;
//   width: 100%;
//   max-width: 700px; /* Adjust the max-width as needed */
// `;

// const ModalContent = styled.div`
//   padding: 20px;
// `;

// const CloseButton = styled.button`
//   background-color: #3498db; /* Use your primary color */
//   color: white;
//   padding: 10px 20px;
//   border-radius: 5px;
//   cursor: pointer;
//   transition: background-color 0.3s ease;

//   &:hover {
//     background-color: #2073c0; /* Use your hover color */
//   }
// `;

// const Calendly = ({ isOpen, onClose }) => {
//   const [isMounted, setIsMounted] = useState(false);

//   useEffect(() => {
//     setIsMounted(true);

//     // Add or remove 'overflow-hidden' class on the body when the modal is open
//     if (isOpen) {
//       document.body.classList.add("overflow-hidden");
//     } else {
//       document.body.classList.remove("overflow-hidden");
//     }

//     // Cleanup the effect
//     return () => {
//       document.body.classList.remove("overflow-hidden");
//     };
//   }, [isOpen]);

//   return (
//     <>
//       {isMounted && isOpen && (
//         <ModalOverlay>
//           <ModalContainer>
//             <ModalContent>
//               <InlineWidget url="https://calendly.com/mithilastack/30min" />
//               {/* Close button */}
//               <div style={{ flex: "1" }} />
//               <div style={{ textAlign: "center", marginBottom: "15px" }}>
//                 <CloseButton
//                   type="button"
//                   onClick={onClose}
//                   aria-label="Schedule Your First Free Advisory Session"
//                 >
//                   Close
//                 </CloseButton>
//               </div>
//             </ModalContent>
//           </ModalContainer>
//         </ModalOverlay>
//       )}
//     </>
//   );
// };

// export default Calendly;


// import React, { useEffect, useState } from "react";
// import styled from "styled-components";
// import Modal from "react-modal";



// const customStyles = {
//   content: {
//     top: "82px", // Add top margin to appear below the header
//     left: "50%",
//     right: "auto",
//     bottom: "auto",
//     marginRight: "-50%",
//     transform: "translate(-50%, 0)",
//     width: "110%", // Default width
//     maxWidth: "600px", // Default max-width
//     height: "100%", // Default height
//     borderRadius: "12px",
//     padding: "30px",
//     textAlign: "center",
//     overflow: "auto",
  
//     "@media (max-width: 768px)": {
//       top: "0px", // Set top margin to 0 for mobile screens
//     },
//   },
//   overlay: {
//     backgroundColor: "rgba(0, 0, 0, 0.6)",
//   },
// };

// const CloseButton = styled.button`
//   background-color: #3498db; /* Use a red color for the close button */
//   color: white;
//   padding: 11px 25px;
//   border: none;
  

//   border-radius: 5px;
//   cursor: pointer;

//   &:hover {
//     background-color: #c0392b; /* Darken the color on hover */
//   }
// `;

// const CalendlyWidget = styled.div`
// margin-top: 0px;
//   min-width: 320px;
//   height: 77%;
//   overflow: hidden;

//   @media (max-width: 768px) {
//     min-width: 100%;
//   }
// `;

// const CalendlyModal = ({ isOpen, onClose }) => {
//   const [isMounted, setIsMounted] = useState(false);

//   useEffect(() => {
//     if (isOpen) {
//       // Load Calendly script
//       const script = document.createElement("script");
//       script.src = "https://assets.calendly.com/assets/external/widget.js";
//       script.async = true;
//       document.body.appendChild(script);

//       // Cleanup script when the modal is closed
//       return () => {
//         document.body.removeChild(script);
//       };
//     }
//   }, [isOpen]);

//   useEffect(() => {
//     setIsMounted(true);

//     // Add or remove 'overflow-hidden' class on the body when the modal is open
//     if (isOpen) {
//       document.body.classList.add("overflow-hidden");
//     } else {
//       document.body.classList.remove("overflow-hidden");
//     }

//     // Cleanup the effect
//     return () => {
//       document.body.classList.remove("overflow-hidden");
//     };
//   }, [isOpen]);

//   return (
//     <>
//       {isMounted && (
//         <Modal
//           isOpen={isOpen}
//           onRequestClose={onClose}
//           style={customStyles}
//           contentLabel="Calendly Modal"
//           ariaHideApp={false}
//         >
//           <CalendlyWidget
//             className="calendly-inline-widget"
//             data-url="https://calendly.com/mithilastack/30min"
//             onClick={(e) => e.stopPropagation()} // Prevent closing modal on widget click
//           ></CalendlyWidget>
//           <div>
//             <CloseButton
//               type="button"
//               onClick={onClose}
//               aria-label="Close the scheduling session"
//             >
//               Close
//             </CloseButton>
//           </div>
//         </Modal>
//       )}
//     </>
//   );
// };

// export default CalendlyModal;




import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Modal from "react-modal";

const CloseButton = styled.button`
  background-color: #3498db;
  color: white;
  padding: 11px 25px;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #c0392b;
  }
`;

const CalendlyWidget = styled.div`
  margin-top: 0px;
  min-width: 320px;
  height: 77%;
  overflow: hidden;

  @media (max-width: 768px) {
    margin-top: 52px;
    min-width: 100%;
    height: 87%;
  }
`;

const CalendlyModal = ({ isOpen, onClose }) => {
  const [isMounted, setIsMounted] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    setIsMounted(true);
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (isOpen) {
      const script = document.createElement("script");
      script.src = "https://assets.calendly.com/assets/external/widget.js";
      script.async = true;
      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    }
  }, [isOpen]);

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }

    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, [isOpen]);

  return (
    <>
      {isMounted && (
        <Modal
          isOpen={isOpen}
          onRequestClose={onClose}
          style={{
            content: {
              top: isMobile ? "0" : "82px",
              left: "50%",
              right: "auto",
              bottom: "auto",
              marginRight: "-50%",
              transform: "translate(-50%, 0)",
              width: "110%",
              maxWidth: isMobile ? "120%" : "600px",
              height: "100%",
              borderRadius: "12px",
              padding: "30px",
              textAlign: "center",
              overflow: "auto",
            },
            overlay: {
              backgroundColor: "rgba(0, 0, 0, 0.6)",
            },
          }}
          contentLabel="Calendly Modal"
          ariaHideApp={false}
        >
          <CalendlyWidget
            className="calendly-inline-widget"
            data-url="https://calendly.com/mithilastack/30min"
            onClick={(e) => e.stopPropagation()}
          ></CalendlyWidget>
          <div>
            <CloseButton
              type="button"
              onClick={onClose}
              aria-label="Close the scheduling session"
            >
              Close
            </CloseButton>
          </div>
        </Modal>
      )}
    </>
  );
};

export default CalendlyModal;
